import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./service.module.css";

import Title from "../../../components/share/title";
import Spinner from "../../../components/loader/Loader";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.scss";

class Gallery extends Component {
  ItemsFilter = (DB) => {
    return DB.filter(function (item) {
      return item.category === "covers";
    });
  };

  render() {
    const DB = this.props.mainDB;
    return (
      <div className={styles.content_service_box}>
        {DB.length > 1 ? (
          <Swiper spaceBetween={10} slidesPerView={1}>
            {this.ItemsFilter(DB).map((item) => (
              <SwiperSlide key={item.id}>
                <NavLink to={"/" + item.service_path + "/1"}>
                  <img
                    className={styles.content_service_img}
                    src={item.service_img}
                    title={item.service_title}
                    alt={item.service_alt}></img>
                </NavLink>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className={styles.content_service_box_spinner}>
            <Spinner params={"18vw"} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mainDB: state.mainDB,
});

export default connect(mapStateToProps, null)(Gallery);
