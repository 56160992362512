import { createAction, createReducer } from '@reduxjs/toolkit';
const initialState = ({
    mainDB: [],
});


const getMainDbSuccess = createAction('GET_MAIN_DB_SUCCESS');

const mainDbReducer = createReducer(initialState, {
    [getMainDbSuccess]: (state, action) => action.payload
},
);

export default mainDbReducer;