import { createAction, createReducer } from '@reduxjs/toolkit';
const initialState = ({
    basketDB: [],
});

const putItemToBdBuscket = createAction('PUT_ITEM_TO_BD_BUSCKET');
const deleteItemFromBdBuscket = createAction('DELETE_ITEM_FROM_BD_BUSCKET');
const updateItemFromBdBuscket = createAction('UPDATE_ITEM_FROM_BD_BUSCKET');
const clearItemsFromBdBascket = createAction('CLEAR_ITEMS_FROM_BD_BUSCKET');

const buscetDbAddItemReducer = createReducer(initialState, {
    [putItemToBdBuscket]: (state, action) => {
        return {
            basketDB: state.basketDB.concat(action.payload)
        }

    },
    [deleteItemFromBdBuscket]: (state, action) => {
        return {
            basketDB: state.basketDB.filter(item => item.id !== action.payload)
        }
    },
    [updateItemFromBdBuscket]: (state, action) => {
        return {
            basketDB: state.basketDB.map(item => item.id === action.payload.id ? { ...item, [action.payload.key]: Number(action.payload.value) } : item)

        }
    },
    [clearItemsFromBdBascket]: (state, action) => {
        return {
            basketDB: action.payload

        }
    },



},

)


export default buscetDbAddItemReducer;