import { createAction, createReducer } from '@reduxjs/toolkit';
const initialState = ({
    reviewsDB: [],
});


const getReviewsDbSuccess = createAction('GET_REVIEWS_DB_SUCCESS');
const clearItemsFromReviewsDb = createAction('CLEAR_ITEMS_FROM_REVIEWS_DB');

const reviewsDbReducer = createReducer(initialState, {
    [getReviewsDbSuccess]: (state, action) => action.payload,
    [clearItemsFromReviewsDb]: (state, action) => {
        return {
            reviewsDB: action.payload

        }
    },

},

);

export default reviewsDbReducer;