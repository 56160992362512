import React from "react";
import styles_share from "../../styles/share.module.css";
import { isMobileOnly } from "react-device-detect";

const Title = ({ title }) => (
  <div className={styles_share.block_title_box}>
    <div className={styles_share.block_title_line_left_box}>
      <div className={styles_share.block_title_line_left_up}></div>
      <div className={styles_share.block_title_line_left_dawn}></div>
    </div>
    <div>
      <h1 className={isMobileOnly ? styles_share.mob_block_title : styles_share.block_title}>
        {title}
      </h1>
    </div>

    <div className={styles_share.block_title_line_right_box}>
      <div className={styles_share.block_title_line_right_up}></div>
      <div className={styles_share.block_title_line_right_dawn}></div>
    </div>
  </div>
);

export default Title;
