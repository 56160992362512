import React, { Component, Fragment } from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import styles from "../header.module.css";
import { isMobileOnly } from "react-device-detect";
import * as basketDBActions from "../../../redux/basketDB/basketDBActions";

class Buscket extends Component {
  componentDidUpdate = (previousProps, previousState) => {
    if (this.props.mainDB.length > 0) {
      if (previousProps.basketDB === this.props.basketDB) {
        const currentWishes = JSON.parse(localStorage.getItem("bascketWishes"));
        //if there is no bd on localstorage, skip building bascket cards
        if (currentWishes) {
          //Проверяем наличие активных карточек в LocalStorage
          let totalQuantityLocalStorage = 0;
          for (let i = 0; i < currentWishes.length; i++) {
            let key = Object.keys(currentWishes[i]);
            let value = Object.values(currentWishes[i]);
            const filterdItem = this.props.mainDB.filter(function (item) {
              return item.id === key[0];
            });
            totalQuantityLocalStorage = totalQuantityLocalStorage + Number(value);
            //Добавляем в каждую карточку товаров, кол-во штук, которые были выбраны юзером

            const addedData = filterdItem.map((item) => ({
              ...item,
              quantity: Number(value),
            }));
            //prevent double render of bascket, when email send
            if (this.props.basketDB.length > 0) {
              return;
            } else {
              this.props.buscketDBAddItem(addedData);
            }
            this.props.countTotalQuantity();
            this.props.countTotalPrice(this.props.mainDB);
          }
        }
      }
    }
  };

  render() {
    const { basketDB, totalPrice, totalQuantity } = this.props;

    return isMobileOnly ? (
      <Fragment>
        <Link to={"/basket"} className={styles.mob_header_basket_link_styles}>
          {basketDB.length > 0 ? (
            <div className={styles.mob_header_basket_quantity}>{totalQuantity} :всего</div>
          ) : (
            <div className={styles.mob_header_basket_quantity}>0: всего</div>
          )}
          {basketDB.length > 0 ? (
            <div className={styles.mob_header_basket_price}>{totalPrice} :сумма</div>
          ) : (
            <div className={styles.mob_header_basket_price}>0: сумма</div>
          )}
        </Link>
      </Fragment>
    ) : (
      <div className={styles["basket-box"]}>
        <div>
          <NavLink to="/basket" exact>
            <img
              src="/assets/img/basket.png"
              className={styles["basket-img"]}
              title="Корзина желаний"
              alt="Корзина желаний"></img>
          </NavLink>
        </div>
        <div>
          <span className={styles["basket-title"]}>Желания</span>
        </div>
        <div className={styles["basket-order-box"]}>
          {basketDB.length > 0 ? (
            <div className={styles["basket-order-box-quantity"]}>всего: {totalQuantity}</div>
          ) : (
            <div className={styles["basket-order-box-quantity"]}>всего: 0</div>
          )}

          <div className={styles["basket-order-devideLine"]}>|</div>
          {basketDB.length > 0 ? (
            <div className={styles["basket-order-box-price"]}>сумма: {totalPrice}</div>
          ) : (
            <div className={styles["basket-order-box-price"]}>сумма: 0</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  basketDB: state.basketDB.basketDB,
  mainDB: state.mainDB,
  totalQuantity: state.totalQuantity.totalQuantity,
  totalPrice: state.totalPrice.totalPrice,
});

const mapDispatchToProps = (dispatch) => ({
  countTotalQuantity: () => dispatch(basketDBActions.countTotalQuantity()),
  countTotalPrice: (mainDB) => dispatch(basketDBActions.countTotalPrice(mainDB)),
  buscketDBAddItem: (items) => {
    dispatch(basketDBActions.putItemToBdBuscket(items));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Buscket);
