import axios from "axios";



const secretKeyDB = process.env.REACT_APP_SECRET_KEY_DB;
const localUrlDB = process.env.REACT_APP_LOCAL_URL_DB;
const dbIdMainDB = process.env.REACT_APP_DB_ID_MAINDB;
const dbIdReviesDB = process.env.REACT_APP_DB_ID_REVIEWSDB;

const NPSecretKey = process.env.REACT_APP_SECRET_KEY_NP;
const NPLocalUrl = process.env.REACT_APP_LOCAL_URL_NP;

export const fetchMainDB = () => (
    axios.get(localUrlDB + dbIdMainDB, {
        headers: {
            "Security-key": secretKeyDB
        }
    })
)

export const updateDB = (data) => (
    axios.put(localUrlDB + dbIdMainDB,
        data,
        {
            headers: {
                "Content-type": "application/json",
                "Security-key": secretKeyDB
            }
        })
)



export const fetchReviewsDB = () => (
    axios.get(localUrlDB + dbIdReviesDB, {
        headers: {
            "Security-key": secretKeyDB
        }
    })
)

export const updateReviewsBD = (data) => (
    axios.put(localUrlDB + dbIdReviesDB,
        data,
        {
            headers: {
                "Content-type": "application/json; charset=utf-8",
                "Security-key": secretKeyDB
            }
        })
)


export const fetchNpCityDB = () => (
    axios.post(NPLocalUrl,
        {
            'apiKey': NPSecretKey,
            "modelName": "Address",
            "calledMethod": "getCities",
            'methodProperties': {
                'Language': 'ru'
            }
        },
        {
            headers: {
                "Content-type": "application/json",
            }
        }).then(response => response.data.data.filter(function (item) {
            return item.IsBranch.match(1)
        })
        )
)
export const fetchNpWareHouseDB = (cityNpId) => (
    axios.post(NPLocalUrl,
        {
            'apiKey': NPSecretKey,
            "modelName": "AddressGeneral",
            "calledMethod": "getWarehouses",
            'methodProperties': {
                'Language': 'ru'
            }
        },
        {
            headers: {
                "Content-type": "application/json",
            }
        }).then(response => response.data.data.filter(function (item) {
            return item.CityRef.match(cityNpId)
        })
        )
)