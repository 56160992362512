import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import styles from "./header.module.css";
import Basket from "../../../components/header/basket/basket";

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

// Import burgermenu and droplist
import CheeseburgerMenu from "cheeseburger-menu";
import HamburgerMenu from "react-hamburger-menu";

import Messangers from "../../../components/header/messangers";
import Gallery from "../gallery/gallery";
import Service from "../service/service";
import Footer from "../../../components/footer/socials";

export default class App extends Component {
  state = {
    menuOpen: false,
  };

  openMenu() {
    this.setState({ menuOpen: true });
    disableBodyScroll();
  }

  closeMenu() {
    /* enableBodyScroll(this.targetElement); */
    clearAllBodyScrollLocks();
    this.setState({ menuOpen: false });
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.header_wrapper}>
          <div className={styles.header_wrapper_menu}>
            <CheeseburgerMenu
              isOpen={this.state.menuOpen}
              closeCallback={this.closeMenu.bind(this)}
              width={700}>
              <div className={styles.dropdown_box}>
                <div className={styles.dropdown_title}>Chebrets Shop</div>
                <ul>
                  <Link
                    to={"/" + "candles" + "/1"}
                    className={styles.menu_item}
                    onClick={this.closeMenu.bind(this)}>
                    <li>свечная мастерская</li>
                  </Link>
                  <Link
                    to={"/" + "taro" + "/1"}
                    className={styles.menu_item}
                    onClick={this.closeMenu.bind(this)}>
                    <li>расклады ТАРО</li>
                  </Link>
                  <Link
                    to={"/" + "aroma" + "/1"}
                    className={styles.menu_item}
                    onClick={this.closeMenu.bind(this)}>
                    <li>аромапалочки</li>
                  </Link>
                  <Link
                    to={"/basket"}
                    className={styles.menu_item}
                    onClick={this.closeMenu.bind(this)}>
                    <li>список желаний</li>
                  </Link>
                  <Link
                    to={"/payment"}
                    className={styles.menu_item}
                    onClick={this.closeMenu.bind(this)}>
                    <li>оплата</li>
                  </Link>
                  <Link
                    to={"/delivery"}
                    className={styles.menu_item}
                    onClick={this.closeMenu.bind(this)}>
                    <li>Виды доставки</li>
                  </Link>
                  <Link
                    to={"/delivery_cost"}
                    className={styles.menu_item}
                    onClick={this.closeMenu.bind(this)}>
                    <li>Стоимость доставки</li>
                  </Link>
                  <Link
                    to={"/exchange"}
                    className={styles.menu_item}
                    onClick={this.closeMenu.bind(this)}>
                    <li>Возврат и обмен</li>
                  </Link>
                  <li className={styles.menu_item}>отзывы</li>
                  <Link
                    to={"/about"}
                    className={styles.menu_item}
                    onClick={this.closeMenu.bind(this)}>
                    <li>про меня</li>
                  </Link>
                </ul>
              </div>
              <div className={styles.contacts_box}>
                <Messangers />
              </div>
              <div className={styles.dropdawn_tagline}>
                «Я сама себя вытащила наверх за бретельки собственного бюстгальтера» Мадонна
              </div>
            </CheeseburgerMenu>

            <HamburgerMenu
              isOpen={this.state.menuOpen}
              menuClicked={this.openMenu.bind(this)}
              width={72}
              height={44}
              strokeWidth={5}
              rotate={0}
              color="#EFEFEF"
              borderRadius={0}
              animationDuration={0.5}
              className={styles.burger}
            />
          </div>
          <div className={styles.header_wrapper_logo}>
            <NavLink to={"/"}>
              <img
                src="/assets/img/logo_mobile.png"
                className={styles.logo_img}
                title="Chebrets Shop"
                alt="Chebrets Shop"></img>
            </NavLink>
          </div>
          <div className={styles.header_wrapper_basket}>
            <Basket />
          </div>
        </div>
      </div>
    );
  }
}
