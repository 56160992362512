import React from "react";
import { NavLink } from 'react-router-dom';
import styles from './header.module.css';

// add this attribute to NavLink and no addition classes you need to make your link be active 
// activeStyle={activeStyle}
/* const activeStyle = {
    color: '#D500DA',
    fontSize: '28px'
}
 */
const Nav = () => (
    < div className={styles['nav-box']} >
        <div className={styles['nav-shedule']}><span>10:00 - 18:00 (Пн - Вс)</span></div>
        <ul className={styles['nav-ul']}>
            <li >
                <NavLink to="/candles/1" className={window.location.pathname.includes('/candles/') ? styles.activeStyle : styles['nav-li']}>СВЕЧИ</NavLink>
            </li>
            <li >
                <NavLink to="/taro/1" className={window.location.pathname.includes('/taro/') ? styles.activeStyle : styles['nav-li']}>ТАРО</NavLink>
            </li>
            <li >
                <NavLink to="/aroma/1" className={window.location.pathname.includes('/aroma/') ? styles.activeStyle : styles['nav-li']}>АРОМА</NavLink>
            </li>
        </ul>
    </div >
)
export default Nav;