import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactStars from 'react-rating-stars-component';
import style from './modal.module.css';
import Reviews from '../../info/reviews'
import CooperationForm from '../../info/CooperationForm'
import Contacts from '../../info/Contacts'
import shortid from 'shortid';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import * as notice from '../../share/pnotify'
import * as api from '../../../services/db-api'
import * as reviewsDbOperations from '../../../redux/reviewsDB/reviewsDbOperations'
import * as reviewsDbActions from '../../../redux/reviewsDB/reviewsDbActions'






class ModalTopBar extends Component {
    state = {
        currentPage: this.props.currentTargetIndex,
        todosPerPage: 1,
        name: '',
        review: '',
        ratings: 0,
        starEditable: true,
        showBackCall: false,
        isCooperation: false
    };

    targetElement = null;


    componentDidMount() {
        window.addEventListener('keydown', this.onEscPress);
        disableBodyScroll(this.targetElement);
        this.clearCirclesPaginationStyle(this.state.currentPage)

        //insert js script of NP conditions
        this.closeModalNPconditions()
        this.createModalNPconditions()
        const modalCooperationButton = document.getElementById('cooperation')
        modalCooperationButton && modalCooperationButton.addEventListener('click', () => {
            this.cooperationHandler()
        }, false);
    }

    componentDidUpdate() {
        this.closeModalNPconditions()
        this.createModalNPconditions()
        this.scrollToTop()

        const modalCooperationButton = document.getElementById('cooperation')
        modalCooperationButton && modalCooperationButton.addEventListener('click', () => {
            this.cooperationHandler()
        }, false)

    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onEscPress);
        const modalCooperationButton = document.getElementById('cooperation')
        modalCooperationButton && modalCooperationButton.removeEventListener('click', () => {
            this.cooperationHandler()
        }, false);
        enableBodyScroll(this.targetElement);
        clearAllBodyScrollLocks();
    }



    onCancelClick = event => {
        window.location.hash = ''
        if (event.target !== event.currentTarget) {
            return
        }
        const modalNP = document.getElementById('npw-terms-wrapper')
        if (modalNP && modalNP.classList.contains('npw-display-block')) {
            modalNP.classList.remove('npw-display-block');

        } else {
            this.props.closeModalItemCard()

        }

    };

    onEscPress = e => {
        if (e.key === 'Escape') {
            window.location.hash = ''
            const modalNP = document.getElementById('npw-terms-wrapper')
            if (modalNP && modalNP.classList.contains('npw-display-block')) {
                modalNP.classList.remove('npw-display-block');

            } else {
                this.props.closeModalItemCard()
            }
        } else { return }

    }



    closeModalNPconditions = () => {
        if (this.props.dbToPrint[0].title === 'Виды доставки') {
            const script = document.getElementById('terms')
            const modalNP = document.getElementById('npw-terms-wrapper')
            if (script) {
                script.remove();
                modalNP.remove();
            }
        }
    }

    test = () => {
        console.log("Hello world")
        const modalNP = document.getElementById('npw-terms-wrapper')

        /* console.log(modalNP)
        setTimeout(this.testt, 3000)
        console.log(modalNP) */
    }

    /*    testt = () => {
        const modalNP = document.getElementById('npw-terms-wrapper')
        modalNP.style.top = '50%'
        modalNP.style.left = '50%'
        modalNP.style.transform = 'translate(-50%,-50%)'
    } */

    createModalNPconditions = () => {
        //insert js script of NP conditions
        if (this.props.dbToPrint.length > 0 && this.props.dbToPrint[0].title === 'Виды доставки' && this.state.currentPage === 0) {
            const script = document.createElement("script");
            script.src = "https://apimgmtstorelinmtekiynqw.blob.core.windows.net/content/MediaLibrary/Widget/Terms/dist/terms.min.js";
            script.id = 'terms'
            document.body.appendChild(script);

            //another way to fetch script
            /*  fetch('https://apimgmtstorelinmtekiynqw.blob.core.windows.net/content/MediaLibrary/Widget/Terms/dist/terms.min.js')
                 .then((response) => response.text()).then((text) => eval(text)) */
        }
    }



    //Function that moves scroll into div content wrapper to top, after pagination
    scrollToTop() {
        const contentWrapper = document.getElementById('content-wrapper')
        if (contentWrapper) {
            contentWrapper.scrollTo({ top: 0, behavior: 'smooth' })
        } else {
            return
        }
    }


    handleClickPagination = (event) => {

        //Проверяем, что событие произошло на кольце пагинации
        if (event.target.tagName !== 'DIV') {
            return
        }
        this.setState({
            currentPage: Number(event.target.id)
        })
        this.clearCirclesPaginationStyle(event)
        window.location.hash = ''
    }

    handleClickLeft = () => {
        if (this.state.currentPage === 0) {
            return
        } else {
            this.setState({
                currentPage: this.state.currentPage - 1
            });
        }
        let currentCircle = this.state.currentPage - 1
        this.clearCirclesPaginationStyle(currentCircle)
    }

    handleClickRight = () => {
        const lastPage = Math.ceil(this.props.dbToPrint.length / this.state.todosPerPage)
        if (this.state.currentPage + 1 === lastPage) {
            return
        } else {
            this.setState({
                currentPage: this.state.currentPage + 1
            });
            window.location.hash = ''
        }
        let currentCircle = this.state.currentPage
        this.clearCirclesPaginationStyle(currentCircle + 1)
    }

    handleChangeRating = value => {
        this.setState({
            rating: value,
        });
    };

    changeHandler = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    clearCirclesPaginationStyle = (event) => {
        this.state.isCooperation && this.setState({
            isCooperation: false
        })
        let btnFilterPaginationCircles = document.getElementsByClassName(style.page_counter_li_circle),
            btnFilterPaginationCirclesLength = btnFilterPaginationCircles.length;
        for (let i = 0; i < btnFilterPaginationCirclesLength; i++) {
            btnFilterPaginationCircles[i].classList.remove(style.page_counter_li_circle_active);
        }
        if (event === this.state.currentPage - 1) {
            btnFilterPaginationCircles[this.state.currentPage - 1].classList.add(style.page_counter_li_circle_active);
        }
        else if (!Number.isInteger(event)) {
            event.target.classList.add(style.page_counter_li_circle_active)
        }
        else {
            btnFilterPaginationCircles[event].classList.add(style.page_counter_li_circle_active);
        }
    }

    submitHandler = (e) => {
        e.preventDefault();
        const now = new Date();
        const day = ('0' + now.getDate()).slice(-2);
        const month = ('0' + (now.getMonth() + 1)).slice(-2);
        const currentReview = {
            'name': this.state.name,
            'rating': this.state.rating,
            'review': this.state.review,
            'data': (day) + '.' + (month) + '.' + now.getFullYear(),
            'id': shortid.generate()
        }
        const data = [...this.props.reviewsDB, currentReview]

        this.updateBD(data)

    }

    updateBD = (data) => {
        api
            .updateReviewsBD(data)
            .then((message) => {
                if (message.status === 200) {
                    //if db updated succefully, clear reviewBd, and fetch new bd, whith new review
                    //when there will be new bd, reviews box will be updated, becase of props changed...
                    this.props.clearItemsFromReviewsDb()
                    //starEditable - to rerender StarRating block, to clear stars
                    this.setState({
                        name: '',
                        review: '',
                        starEditable: false,
                    })
                    this.props.fetchReviewsDB()
                    this.setState({
                        starEditable: true,
                    })
                    notice.pnotifyOk('Ваш отзыв успешно добавлен')
                } else {
                    notice.pnotifyError('Ошибка сервера. Попробуйте позже')
                }
            })
            .catch(function (error) {
                notice.pnotifyError('Ошибка сервера. Попробуйте позже.')

            })
    }

    //In modal info Contacts watch if user chose to order backcall, if Yes, shows input field
    backCallHandler = () => {
        this.setState({
            showBackCall: !this.state.showBackCall
        })
    }

    cooperationHandler = () => {
        this.setState({
            isCooperation: !this.state.isCooperation
        })

    }

    render() {
        const { currentPage, todosPerPage, name, review, ratings, starEditable, showBackCall, isCooperation } = this.state;
        const { dbToPrint, contactsData } = this.props

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 0; i <= Math.ceil((dbToPrint.length - 1) / todosPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <li
                    className={style.page_counter_li}
                    key={number}
                    onClick={this.handleClickPagination}
                >
                    <div className={style.page_counter_li_circle} id={number}></div>
                </li>
            );
        });


        return (
            <div>
                <div className={style.modal_card_wrapper} onClick={(event) => this.onCancelClick(event)}></div>
                <div className={style.modal_card_container}>
                    <div className={style.modal_topbar_container}>
                        <div className={style.modal_topbar_arrowLeft_box}>
                            <div className={style.arrow_box_left} onClick={this.handleClickLeft} >
                                <div className={style.arrow_right}></div>
                                <div className={style.arrow_left}></div>
                            </div>
                        </div>
                        <div className={style.modal_topbar_content_wrapper}>
                            {dbToPrint[currentPage].category !== 'topbar' &&
                                <div className={style.modal_topbar_content_category}>{dbToPrint[currentPage].category}</div>
                            }
                            <div className={style.modal_topbar_content_title} style={{ fontSize: dbToPrint[currentPage].category === 'topbar' && '48px', color: dbToPrint[currentPage].category === 'topbar' && '#D500DA' }}>{dbToPrint[currentPage].title}</div>
                            <div className={style.modal_topbar_content_box} >
                                {dbToPrint[currentPage].title !== 'Отзывы' ?
                                    dbToPrint[currentPage].title !== 'Контакты' ?
                                        <Fragment>{isCooperation ? <div className={style.modal_card_container_content_txt_box} id='content-wrapper'><CooperationForm cooperationHandler={this.cooperationHandler} /></div> :
                                            <div className={style.modal_card_container_content_txt_box} dangerouslySetInnerHTML={{ __html: dbToPrint[currentPage].description }} id='content-wrapper'></div>
                                        }
                                        </Fragment>
                                        : <div className={style.modal_card_container_content_txt_box}>
                                            <Contacts
                                                backCallHandler={this.backCallHandler}
                                                showBackCall={showBackCall}
                                                closeModal={this.props.closeModalItemCard}
                                                contactsData={contactsData}
                                            />
                                        </div>
                                    : <div className={style.modal_card_container_content_txt_box}>
                                        <Reviews />
                                    </div>}
                                <div className={style.modal_card_container_content_img_box}>
                                    {dbToPrint[currentPage].title !== 'Отзывы' ?
                                        <img
                                            className={style.modal_card_container_content_img + ' ' + style.correct_img}
                                            src={dbToPrint[currentPage].img_src}
                                            title={dbToPrint[currentPage].img_title}
                                            alt={dbToPrint[currentPage].img_alt}></img>
                                        :
                                        <form onSubmit={e => this.submitHandler(e)} className={style.modal_card_review_form}>
                                            <div className={style.modal_card_review_title}>Оставьте свой отзыв</div>
                                            {starEditable &&
                                                <ReactStars
                                                    classNames={style.modal_card_star_rating}
                                                    name='rating'
                                                    count={5}
                                                    value={ratings}
                                                    size={25}
                                                    edit={true}
                                                    onChange={this.handleChangeRating}
                                                    activeColor='#ff6c00'
                                                    isHalf={true}
                                                />}
                                            <input
                                                className={style.modal_card_input_name}
                                                placeholder='ВАШЕ ИМЯ'
                                                type='text'
                                                name='name'
                                                value={name}
                                                autoComplete='off'
                                                onChange={this.changeHandler}
                                                required>
                                            </input>
                                            <div className={style.order_checkout_add_textarea_box}>
                                                <textarea className={style.modal_card_input_review} name='review' value={review} onChange={this.changeHandler}></textarea>
                                            </div>
                                            <div className={style.order_card_send_btn_review_box}>
                                                <button type='submit' className={style.order_card_send_btn_review}>
                                                    отправить</button>
                                            </div>
                                        </form>
                                    }
                                </div>
                            </div>
                            {/*   {dbToPrint[currentPage].title === 'Виды доставки' &&
                                <div className={style.delivery_cost_calc_box} dangerouslySetInnerHTML={{
                                    __html: "<div id='np-term'><button type='button' id='npw-term-open-button' class='' style='width: 257px;'>УСЛОВИЯ ПРЕДОСТАВЛЕНИЯ УСЛУГ</button></div>"
                                }}>
                                </div>} */}

                        </div>
                        <div className={style.modal_topbar_arrowRight_box}>
                            <div className={style.arrow_box_right} onClick={this.handleClickRight}>
                                <div className={style.arrow_right}></div>
                                <div className={style.arrow_left}></div>
                            </div>
                        </div>

                    </div>
                    <ul id='page-numbers' className={style.page_counter_ul}>
                        {renderPageNumbers}
                    </ul>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => ({
    reviewsDB: state.reviewsDb,
})
const mapDispatchToProps = {
    fetchReviewsDB: reviewsDbOperations.fetchReviewsDB,
    clearItemsFromReviewsDb: reviewsDbActions.clearItemsFromReviewsDb,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalTopBar)


/* {dbToPrint[currentPage].title === 'Виды доставки' &&
                                        {/ <div className={style.delivery_cost_calc_box} onClick={this.deliveryCostCalcHandler} dangerouslySetInnerHTML={{
                                            __html: "<div><button type='button' id='npw-term-open-button' class='' style='width: 257px;'>УСЛОВИЯ ПРЕДОСТАВЛЕНИЯ УСЛУГ</button><script type='text/javascript' id='terms' charset='utf-8' data-lang='ru' apiKey='33ce337e1373e00f3f14a1a07866a048' data-town='city-not-default' data-town-name='undefined' data-town-id='undefined' src='https://apimgmtstorelinmtekiynqw.blob.core.windows.net/content/MediaLibrary/Widget/Terms/dist/terms.min.js' console.log('Start')></script></div>"
                                        }}>

                                            {<iframe src='/assets/NP/conditionsNP.html' className={style.delivery_cost_calc_box_iframe} seamless align='middle' frameBorder='no'></iframe>}
                                        </div>}} */