import React, { Component, Fragment } from 'react';
import MaskedInput from 'react-text-mask'
import styles from './info.module.css';
import * as notice from '../share/pnotify'


export default class CooperationForm extends Component {
    state = {
        isOnlineStore: false,
        onlineStoreURL: '',
        isOfflineStore: false,
        offlineStoreAddress: '',
        cooperation: '',
        about: '',
        company: '',
        name: '',
        position: '',
        email: '',
        phone: '',
        city: ''
    }


    submitHandler = e => {
        e.preventDefault();
        window.Email.send({
            SecureToken: "02e97003-3ab4-449b-bd98-2058e3f03876",
            To: 'mavistepreport@gmail.com',
            From: "mavistepreport@gmail.com",
            Subject: "Заявка на сотрудничество",
            Body: `
            <b>Запрос на сотрудничество из ${this.state.city}: </b><br>
            <b>Компания: </b>${this.state.company}<br>
            <b>URL: </b>${this.state.isOnlineStore ? this.state.onlineStoreURL : 'не указано'}<br>
            <b>Адрес магазина: </b>${this.state.isOfflineStore ? this.state.offlineStoreAddress : 'не указано'}<br>
            <b>Описание бизнеса: </b>${this.state.about !== '' ? this.state.about : 'не указано'}<br>
            <b>Вид сотрудничества: </b>${this.state.cooperation !== '' ? this.state.cooperation : 'не указано'}<br><br>
            <b>Контакт: </b>${this.state.name}<br>
            <b>Должность: </b>${this.state.position}<br>
            <b>Телефон: </b>${this.state.phone}<br>
            <b>E-mail: </b>${this.state.email}<br>
            `
        }).then(
            message => this.mailSendResult(message)
        )
    }

    mailSendResult = (message) => {
        if (message === "OK") {
            notice.pnotifyOk("Ваша заявка успешно отправлена!")
            this.setState({
                isOnlineStore: false, onlineStoreURL: '', isOfflineStore: false, offlineStoreAddress: '', cooperation: '', about: '',
                phone: '', company: '', name: '', position: '', email: '', city: ''
            })
            this.props.cooperationHandler()
        } else {
            notice.pnotifyError("Ошибка сервера. Попробуйте позже, или свяжитесь со мной по телефону или мессенджеру")
        }
    }

    changeHandler = e => {
        if (e.target.name === 'isOnlineStore') {
            this.setState({
                isOnlineStore: !this.state.isOnlineStore
            })
        } else if (e.target.name === 'isOfflineStore') {
            this.setState({
                isOfflineStore: !this.state.isOfflineStore
            })
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }

    }

    render() {
        const { isOnlineStore, onlineStoreURL, isOfflineStore, offlineStoreAddress, cooperation, about, company, name, position, email, phone, city } = this.state

        return (
            <div className={styles.cooperation_form} >
                <p className={styles.cooperation_title}>Форма партнерства</p>
                <form onSubmit={e => this.submitHandler(e)} >
                    <div className={styles.cooperation_form_onlinestore_box}>
                        <span className={styles.cooperation_form_question}>1. <span style={{ 'textDecoration': 'underline' }}>Есть ли у вас интернет-магазин?</span></span>
                        <label>
                            <input
                                type="radio"
                                name="isOnlineStore"
                                value="Да"
                                onChange={this.changeHandler}
                                className={styles.cooperation_form_label_text}
                            />Да
                                        </label>
                        <label>
                            <input
                                type="radio"
                                name="isOnlineStore"
                                value="Нет"
                                defaultChecked
                                onChange={this.changeHandler}
                                className={styles.cooperation_form_label_text}
                            />Нет
                                        </label>
                        {isOnlineStore && <input
                            className={styles.cooperation_form_onlinestore_input}
                            placeholder='www.examle.com'
                            type='text'
                            name='onlineStoreURL'
                            value={onlineStoreURL}
                            autoComplete='off'
                            onChange={this.changeHandler}
                        >
                        </input>}
                    </div>

                    <div className={styles.cooperation_form_onlinestore_box}>
                        <span className={styles.cooperation_form_question} >2. <span style={{ 'textDecoration': 'underline' }}>Есть ли у вас розничный магазин?</span></span>
                        <label>
                            <input
                                type="radio"
                                name="isOfflineStore"
                                value="Да"
                                onChange={this.changeHandler}
                                className={styles.cooperation_form_label_text}
                            />Да
                                        </label>
                        <label>
                            <input
                                type="radio"
                                name="isOfflineStore"
                                value="Нет"
                                defaultChecked
                                onChange={this.changeHandler}
                                className={styles.cooperation_form_label_text}
                            />Нет
                                        </label>
                        {isOfflineStore && <input
                            className={styles.cooperation_form_onlinestore_input}
                            placeholder='г. Киев, ул.Хрещатик, 1 маг-н "Любимая"'
                            type='text'
                            name='offlineStoreAddress'
                            value={offlineStoreAddress}
                            autoComplete='off'
                            onChange={this.changeHandler}
                        >
                        </input>}
                    </div>
                    <div className={styles.cooperation_form_cooperation_textarea_box}>
                        <span className={styles.cooperation_form_question} >3. <span style={{ 'textDecoration': 'underline' }}>Уточните интересующий вид сотрудничества:</span></span>
                        <textarea className={styles.cooperation_form_cooperation_textarea} name='cooperation' value={cooperation} onChange={this.changeHandler}></textarea>
                    </div>
                    <div className={styles.cooperation_form_about_textarea_box}>
                        <span className={styles.cooperation_form_question} >4. <span style={{ 'textDecoration': 'underline' }}>Напишите о себе, Вашем бизнесе и почему именно мой продукт:</span></span>
                        <textarea className={styles.cooperation_form_about_textarea} name='about' value={about} onChange={this.changeHandler}></textarea>
                    </div >
                    <span className={styles.cooperation_form_question} style={{ 'marginTop': '4%' }}>5. <span style={{ 'textDecoration': 'underline' }}>Ваши контактные данные</span></span>
                    <div className={styles.cooperation_form_contacts_box}>

                        <div className={styles.cooperation_form_contacts_first_block}>
                            <input
                                className={styles.cooperation_form_contacts_input}
                                placeholder='Компания'
                                type='text'
                                name='company'
                                value={company}
                                autoComplete='off'
                                onChange={this.changeHandler}
                                required>
                            </input>
                            <input
                                className={styles.cooperation_form_contacts_input}
                                placeholder='Ф.И.О.'
                                type='text'
                                name='name'
                                value={name}
                                autoComplete='off'
                                onChange={this.changeHandler}
                                required>
                            </input>
                            <input
                                className={styles.cooperation_form_contacts_input}
                                placeholder='Занимаемаея должность'
                                type='text'
                                name='position'
                                value={position}
                                autoComplete='off'
                                onChange={this.changeHandler}
                                required>
                            </input>
                        </div>
                        <div className={styles.cooperation_form_contacts_second_block}>
                            <MaskedInput
                                mask={['+', '3', '8', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                className={styles.cooperation_form_contacts_input}
                                placeholder="телефон"
                                guide={false}
                                id="my-input-id"
                                onBlur={() => { }}
                                name="phone"
                                value={phone}
                                autoComplete="off"
                                onChange={this.changeHandler}
                                required
                            />
                            <input
                                className={styles.cooperation_form_contacts_input}
                                placeholder='e-mail'
                                type='text'
                                name='email'
                                value={email}
                                autoComplete='off'
                                onChange={this.changeHandler}
                                required>
                            </input>
                            <input
                                className={styles.cooperation_form_contacts_input}
                                placeholder='город'
                                type='text'
                                name='city'
                                value={city}
                                autoComplete='off'
                                onChange={this.changeHandler}
                                required>
                            </input>
                        </div>
                    </div>
                    <div className={styles.cooperation_form_button_box}>
                        <div className={styles.cooperation_form_button_center}>
                            <button onClick={this.props.cooperationHandler} className={styles.cooperation_form_button_back}>Назад</button>
                        </div>
                        <div className={styles.cooperation_form_button_center}>
                            < button type='submit' className={styles.cooperation_form_button_send} >
                                Отправить</button>
                        </div>
                    </div>
                </form >

                <hr className={styles.cooperation_underline} />
                <div className={styles.cooperation_underline_txt}><p style={{ 'paddingBottom': 0, 'marginBottom': 0 }}>Благодарю за интерес к моей продукции!</p> <p style={{ 'padding': 0, 'margin': 0 }}>Я свяжусь с Вами в течении сегодняшнего дня</p></div>

            </div >
        );
    }
}

