import { getMainDbSuccess } from "./mainDbActions";
import * as api from "../../services/db-api";
/* import axios from "axios"; */

export const fetchMainDB = () => (dispatch) => {
  api.fetchMainDB().then((response) => dispatch(getMainDbSuccess(response.data)));
};

/* export const fetchMainDB = () => (dispatch) => {
  axios
    .get("http://localhost:3004/data")
    .then((response) => dispatch(getMainDbSuccess(response.data)));
};
 */
