import React, { Component } from "react";
import styles from './header.module.css';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ModalTopBar from "../share/modals/modalTopBar"


class TopBar extends Component {
    state = {
        isActive: false,
        currentTargetIndex: null
    }


    ItemsFilter = (filter, DB) => {
        return DB.filter(function (item) {
            return item.category === filter;
        })
    }



    toggleisActive = e => {
        this.setState({
            isActive: !this.state.isActive
        })
        if (e) {
            this.setState({
                currentTargetIndex: this.ItemsFilter("topbar", this.props.mainDB).findIndex(el => el.id === e.target.id)
            })
        } else { return }
    };

    render() {
        const DB = this.props.mainDB;
        const { isActive, currentTargetIndex } = this.state
        const topBarData = DB.length > 0 && this.ItemsFilter("topbar", DB)
        return (
            <div className={styles['topBar-box']}>
                <ul className={styles['topBar-ul']} >
                    <li>
                        <NavLink to="/" exact>
                            <img src="/assets/img/logo.png" className={styles['topBar-logo-img']} alt="chebrets" title="chebrets"></img>
                        </NavLink>
                    </li>
                    {topBarData.length > 0 &&
                        topBarData.map((item) => (
                            <li key={item.id}>
                                <span className={styles['topBar-li']} onClick={this.toggleisActive} id={item.id}>{item.title}</span>
                            </li>
                        ))
                    }
                </ul>
                {isActive &&
                    <ModalTopBar
                        closeModalItemCard={this.toggleisActive}
                        dbToPrint={topBarData}
                        currentTargetIndex={currentTargetIndex}
                    />
                }
            </div>
        )
    }

}
const mapStateToProps = state => ({
    mainDB: state.mainDB,
})

export default connect(mapStateToProps, null)(TopBar)
