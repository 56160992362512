import React, { Fragment, Component } from "react";
import { connect } from 'react-redux';
import shortid from 'shortid';
import styles from './info.module.css';
import Title from "../share/title";
import ModalInfoBar from "../share/modals/modalTopBar"
import Spinner from "../loader/Loader"


class Info extends Component {
    state = {
        infoHide: false,
        isActive: false,
        currentTargetIndex: null,
        dbToPrint: []
    }

    ItemsFilter = (filter, DB) => {
        return DB.filter(function (item) {
            return item.category === filter;
        })
    }

    toggleisActive = e => {
        this.setState({
            isActive: !this.state.isActive
        })
        if (e) {

            const targetName = e.target.getAttribute('name')
            const targetValue = e.target.getAttribute('value')
            this.setState({
                currentTargetIndex: this.ItemsFilter(targetName, this.props.mainDB).findIndex(el => el.title === targetValue),
                dbToPrint: this.ItemsFilter(targetName, this.props.mainDB)
            })
        } else { return }
    };

    handleModalChange = () => {
        this.setState({ infoHide: !this.state.infoHide });
    }

    render() {
        const { infoHide, currentTargetIndex, isActive, dbToPrint } = this.state;
        const DB = this.props.mainDB;
        const infoData = DB.length > 1 && this.ItemsFilter("info", DB)
        const contactsData = DB.length > 1 && this.ItemsFilter("contacts", DB)

        return (

            <Fragment>
                {infoHide ?
                    <Fragment>
                        <Title title={"ИНФО"} />

                        <div className={styles.arrow_box_up} onClick={this.handleModalChange}>
                            <div className={styles.arrow_right}></div>
                            <div className={styles.arrow_left}></div>
                        </div>
                        {infoData ?
                            <div className={styles.info_blocks_wrapper}>

                                {infoData.length > 0 &&
                                    infoData.map((item) => (
                                        <div className={styles.info_block_box} key={shortid.generate()}>
                                            <h2 className={styles.info_block_title} >{item.title}</h2>
                                            <ul className={styles.info_block_ul} >
                                                <li className={styles.info_block_li} onClick={this.toggleisActive} name={item.title} value={item.subTitle_1}>{item.subTitle_1}</li>
                                                <li className={styles.info_block_li} onClick={this.toggleisActive} name={item.title} value={item.subTitle_2}>{item.subTitle_2}</li>
                                                <li className={styles.info_block_li} onClick={this.toggleisActive} name={item.title} value={item.subTitle_3}>{item.subTitle_3}</li>
                                                <li className={styles.info_block_li} onClick={this.toggleisActive} name={item.title} value={item.subTitle_4}>{item.subTitle_4}</li>
                                            </ul>
                                        </div>
                                    ))}
                            </div>
                            : <Spinner />
                        }
                        {isActive &&
                            <ModalInfoBar
                                closeModalItemCard={this.toggleisActive}
                                dbToPrint={dbToPrint}
                                currentTargetIndex={currentTargetIndex}
                                contactsData={contactsData}
                            />
                        }
                    </Fragment>

                    : <Fragment>
                        <Title title={"ИНФО"} />

                        <div className={styles.arrow_box_dawn} onClick={this.handleModalChange}>
                            <div className={styles.arrow_right}></div>
                            <div className={styles.arrow_left}></div>
                        </div>
                    </Fragment>}

            </Fragment >

        )
    }

}

const mapStateToProps = state => ({
    mainDB: state.mainDB,
})

export default connect(mapStateToProps, null)(Info)
