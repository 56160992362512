import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './info.module.css';
import CallBack from "../footer/footer";
import Messangers from "../header/messangers"

const Contacts = (props) => {
    return (
        <div className={styles.contacts_wrapper}>
            <div className={styles.contacts_top_box}>
                <div className={styles.contacts_top_info}>
                    < div className={styles.contacts_top_logo}>
                        <NavLink to="/" onClick={props.closeModal} className={styles.contacts_nav_img_css}>
                            < img src="/assets/img/logo_square.png" className={styles.contacts_top_img} alt="chebrets" title="chebrets"></img>
                            <span className={styles.contacts_top_url}>chebrets.com.ua</span>
                        </NavLink>
                    </div>
                    <div className={styles.contacts_top_description}>
                        <div className={styles.contacts_top_descript_title}>
                            <NavLink to="/" onClick={props.closeModal} className={styles.contacts_nav_css}>Chebrets Shop</NavLink></div>
                        <div className={styles.contacts_top_descript_ul_box}>
                            <ul className={styles.contacts_top_descript_ul}>
                                <li ><NavLink to="/candles/1" onClick={props.closeModal} className={styles.contacts_nav_css}>- Свечная мастерская</NavLink></li>
                                <li><NavLink to="/taro/1" onClick={props.closeModal} className={styles.contacts_nav_css}>- Расклады ТАРО</NavLink></li>
                                <li><NavLink to="/aroma/1" onClick={props.closeModal} className={styles.contacts_nav_css}>- Аромапалочки</NavLink></li>
                            </ul>
                        </div>
                    </div >
                </div>

                <div className={styles.contacts_top_footer}>
                    {props.showBackCall ?
                        <CallBack contactPage={true} />
                        : <div className={styles.contacts_cursor}><a href={'tel:' + props.contactsData[0].phone} className={styles.contacts_nav_css}>{props.contactsData[0].phone_show}</a></div>}
                    <button className={styles.contacts_top_footer_button} onClick={props.backCallHandler}>Обратный звонок</button>
                </div>
            </div >
            <div className={styles.contacts_footer_box}>
                < div className={styles.contacts_footer_instagram}><Messangers contactPage={true} /></div>
                <div className={styles.contacts_footer_mail}><a href={'mailto:' + props.contactsData[0].mail} className={styles.contacts_footer_mail_link}>{props.contactsData[0].mail}</a></div>
            </div>

        </div >
    );
};

export default Contacts