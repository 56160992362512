import { createAction, createReducer } from '@reduxjs/toolkit';
const initialState = ({
    currentGalleryPage: 1,
});


const putCurrentGalleryPage = createAction('PUT_CURRENT_GALLERY_PAGE');

const currentGalleryPageReducer = createReducer(initialState, {
    [putCurrentGalleryPage]: (state, action) => ({ currentGalleryPage: action.payload })
},
);

export default currentGalleryPageReducer;