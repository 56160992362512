import PNotify from "pnotify/dist/es/PNotify";
import "material-design-icons/iconfont/material-icons.css";
import "pnotify/dist/PNotifyBrightTheme.css";
import "pnotify/dist/es/PNotifyStyleMaterial.js";

import "pnotify/dist/es/PNotifyButtons.js";
import styles from "../../styles/share.module.css";

import { isMobileOnly } from "react-device-detect";

PNotify.defaults.styling = "material";
PNotify.defaults.icons = "material";

export const pnotifyOk = (text) => {
  /* pnotifyOk.addModuleClass(...styles.someClass); */
  let notice = PNotify.success({
    addClass: styles.someClass,
    text: text,
    animateSpeed: "slow",
    delay: 2000,
    /*     width: isMobileOnly && "80vw",
    minHeight: isMobileOnly && "20vw", */
  });
  notice.on("click", function () {
    notice.close();
  });
};

export const pnotifyError = (text) => {
  let notice = PNotify.error({
    text: text,
    animateSpeed: "slow",
    delay: 2000,
  });
  notice.on("click", function () {
    notice.close();
  });
};

export const pnotifyNotice = (text) => {
  let notice = PNotify.notice({
    text: text,
    animateSpeed: "slow",
    delay: 2000,
  });
  notice.on("click", function () {
    notice.close();
  });
};
