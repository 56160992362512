import { getReviewsDbSuccess } from "./reviewsDbActions"
import * as api from "../../services/db-api";




export const fetchReviewsDB = () => dispatch => {
    api.fetchReviewsDB().then(response => dispatch(getReviewsDbSuccess(response.data)))
}


