import React, { Component } from "react";
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import styles from '../main.module.css';

export default class Spinner extends Component {
    //other logic
    render() {
        const { params } = this.props
        return (
            <div className={params ? styles.order_checkout_np_list_spinner : styles.loader}>
                <Loader
                    type="Oval"
                    color="#D500DA"
                    height={params ? params : 100}
                    width={params ? params : 100}
                    timeout={0}

                />
            </div>
        );
    }
}