import React, { Component, Fragment } from "react";
import styles from "./footer.module.css";
import { connect } from "react-redux";
import Spinner from "../../components/loader/Loader";
import Icons from "../../assets/img/sprite.svg";
import { isMobileOnly } from "react-device-detect";

class Socials extends Component {
  getContacts = (mainDB) => {
    const contacts = mainDB.filter(function (item) {
      return item.category === "contacts";
    });
    return contacts;
  };
  render() {
    const mainDB = this.props.mainDB;
    const contacts = mainDB.length > 0 && this.getContacts(mainDB);

    return mainDB.length > 1 ? (
      <Fragment>
        <div
          className={
            isMobileOnly ? styles.footer_socials_circle_mob : styles.footer_socials_circle
          }>
          <a
            href={contacts[0].facebook}
            target="_blank"
            title="Я на Facebook"
            rel="noopener noreferrer">
            <svg
              className={
                isMobileOnly ? styles.footer_socials_svg_box_mob : styles.footer_socials_svg_box
              }>
              <title>Я на Facebook</title>
              <use xlinkHref={`${Icons}#facebook`} className={styles.footer_socials_svg} />
            </svg>
          </a>
        </div>
        <div
          className={isMobileOnly ? styles.footer_socials_circle_mob : styles.footer_socials_circle}
          style={{ marginRight: !this.props.contactPage && 0 }}>
          <a
            href={contacts[0].instagram}
            target="_blank"
            title="Я на Facebook"
            rel="noopener noreferrer">
            <svg
              className={
                isMobileOnly ? styles.footer_socials_svg_box_mob : styles.footer_socials_svg_box
              }>
              <title>Я на Instagram</title>
              <use xlinkHref={`${Icons}#instagram`} className={styles.footer_socials_svg} />
            </svg>
          </a>
        </div>
      </Fragment>
    ) : (
      <div className={styles["messangers-box"]}>
        <Spinner params={isMobileOnly ? "18vw" : 50} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mainDB: state.mainDB,
});

export default connect(mapStateToProps, null)(Socials);
