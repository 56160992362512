import React, { Component } from "react";
import { connect } from 'react-redux';
import styles from './info.module.css';
import Icons from "../../assets/img/sprite.svg";
import ReactStars from 'react-rating-stars-component';
import Spinner from "../loader/Loader";
import * as reviewsDbActions from "../../redux/reviewsDB/reviewsDbOperations"



class Reviews extends Component {

    state = {
        currentPage: 1,
        totalPages: 0,
        todosPerPage: 3,
    };

    componentDidMount() {
        if (!this.props.reviewsDB.length) {
            this.props.fetchReviewsDB()
        }
        if (this.props.reviewsDB.length) {
            this.calcTotalPages()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.reviewsDB !== this.props.reviewsDB) {
            this.calcTotalPages()
            this.setState({
                currentPage: 1
            })
        }
    }

    calcTotalPages = () => {
        this.setState({
            totalPages: Math.ceil(this.props.reviewsDB.length / this.state.todosPerPage) + 1
        });

    }

    handleClickLeft = () => {
        if (this.state.currentPage === 1) {
            return
        } else {
            this.setState({
                currentPage: this.state.currentPage - 1
            });
        }
    }

    handleClickRight = () => {
        if (this.state.currentPage + 1 === this.state.totalPages) {
            return
        } else {
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    }

    workingDB = (DB, newReview, indexOfLastTodo, indexOfFirstTodo) => {
        let copyOfItems = DB.map(obj => ({
            ...obj,
        }));

        return copyOfItems.reverse().slice(indexOfFirstTodo, indexOfLastTodo)

    }

    render() {

        const DB = this.props.reviewsDB;
        const { currentPage, totalPages, todosPerPage } = this.state;

        // Logic for displaying todos
        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const currentDataToPrint = DB.length > 0 && this.workingDB(DB, this.props.newReview, indexOfLastTodo, indexOfFirstTodo)

        return (
            <div className={styles.reviews_wrapper}>
                <div className={styles.reviews_content_box}>
                    <ul className={styles.reviews_content_box_ul}>
                        {DB.length > 0 ?
                            currentDataToPrint.map((item) => (
                                <li className={styles.reviews_content_box_li} key={item.id}>
                                    <p className={styles.reviews_content_box_li_name}>{item.name}</p>
                                    <div className={styles.reviews_content_box_li_rating}>
                                        <ReactStars
                                            name="rating"
                                            count={5}
                                            value={item.rating}
                                            size={18}
                                            edit={false}
                                            activeColor="#ff6c00"
                                            isHalf={true}
                                        />
                                    </div>
                                    <div className={styles.reviews_content_box_li_review}>
                                        {item.review}
                                    </div>
                                    <div className={styles.reviews_content_box_li_review_date}> {item.data}</div>
                                </li>

                            ))
                            : <Spinner />}
                    </ul>
                </div>
                {DB.length > 0 &&
                    <div className={styles.reviews_pagination_box}>

                        <div className={styles.reviews_svg_wrapper} onClick={this.handleClickLeft}>
                            <svg className={styles.reviews_svg_box + " " + styles.rotate} >
                                <title>Назад</title>
                                <use xlinkHref={`${Icons}#arrow`} className={styles.reviews_svg_arrow_right} />
                            </svg>
                        </div>
                        <div className={styles.reviews_current_page_review_box}>
                            <span className={styles.reviews_current_page_review}>{(currentPage)}</span> / {(totalPages - 1)}
                        </div>
                        <div className={styles.reviews_svg_wrapper} onClick={this.handleClickRight}>
                            <svg className={styles.reviews_svg_box} >
                                <title>Вперед</title>
                                <use xlinkHref={`${Icons}#arrow`} className={styles.reviews_svg_arrow_right} />
                            </svg>
                        </div>
                    </div>}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    reviewsDB: state.reviewsDb,
})

const mapDispatchToProps = {
    fetchReviewsDB: reviewsDbActions.fetchReviewsDB,
}

export default connect(mapStateToProps, mapDispatchToProps)(Reviews)