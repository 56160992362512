import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./gallery.module.css";
import { withRouter } from "react-router-dom";

import Title from "../../../components/share/title";
import Spinner from "../../../components/loader/Loader";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.scss";

class Gallery extends Component {
  ItemsGalleryFilter(ItemsGallery) {
    return ItemsGallery.filter(function (item) {
      return item.gallery === true;
    });
  }

  handleClickDetailCard = (category, id) => {
    //Select all items from MainDB according to click category
    const categoryLength = this.props.mainDB.filter(function (item) {
      return item.category === category;
    });
    //Find out ckick element index in array of category
    const itemIndexInArray = categoryLength.findIndex((el) => el.id === id);
    //Find out item pagination page
    const itemPaginationNumber = Math.ceil((itemIndexInArray + 1) / 6);
    //Build path to itemCard
    this.props.history.push({
      pathname: `/` + category + `/` + itemPaginationNumber + `/` + id,
      state: { prevPath: this.props.location.pathname },
    });
  };

  render() {
    const ItemsGallery = this.props.mainDB;
    return (
      <div className={styles.content_gallery_box}>
        {ItemsGallery.length > 0 ? (
          <Swiper spaceBetween={10} slidesPerView={1}>
            {this.ItemsGalleryFilter(ItemsGallery).map((item) => (
              <SwiperSlide key={item.id}>
                <img
                  className={styles.content_gallery_img}
                  src={item.img_src}
                  title={item.title}
                  alt={item.img_alt}
                  onClick={() => {
                    this.handleClickDetailCard(item.category, item.id);
                  }}></img>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className={styles.content_gallery_box_spinner}>
            <Spinner params={"18vw"} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mainDB: state.mainDB,
});

export default connect(mapStateToProps, null)(withRouter(Gallery));
