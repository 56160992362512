import { createAction } from '@reduxjs/toolkit';


export const putItemToBdBuscket = createAction('PUT_ITEM_TO_BD_BUSCKET', function prepare(items) {
    return {
        payload: items
    }
})

export const deleteItemFromBdBuscket = createAction('DELETE_ITEM_FROM_BD_BUSCKET', function prepare(id) {
    return {
        payload: id
    }
})


export const updateItemFromBdBuscket = createAction('UPDATE_ITEM_FROM_BD_BUSCKET', function prepare(id, key, value) {
    return {
        payload: {
            id: id,
            key: key,
            value: value
        }
    }
})

export const countTotalQuantity = createAction('TOTAL_QUANTITY_FROM_BD_BUSCKET', function prepare(bascketDB) {
    return {
        payload: bascketDB
    }
})

export const countTotalPrice = createAction('TOTAL_PRICE_FROM_BD_BUSCKET', function prepare(mainDB) {
    return {
        payload: mainDB
    }
})

export const clearItemsFromBdBascket = createAction('CLEAR_ITEMS_FROM_BD_BUSCKET', function prepare() {
    return {
        payload: []
    }
})

