import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import styles from './header.module.css';
import Nav from "./nav"
import Messangers from "./messangers"
import Basket from "./basket/basket"
import TopBar from "./topBar"


export default class Header extends Component {

    render() {
        return (
            <div>
                <div className={styles.header}>
                    <NavLink to="/" exact>
                        <img src="/assets/img/logo_whithout_oval.png" className={styles['logo-img']} alt="chebrets" title="chebrets"></img>
                    </NavLink>
                    <Nav />
                    <Messangers />
                    <Basket />
                </div>
                <div className={styles.header_line}></div>
                <TopBar />
            </div>
        )
    }

}

