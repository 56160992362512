import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Spinner from "../loader/Loader";
import Icons from "../../assets/img/sprite.svg";
import styles from "./header.module.css";
import { isMobileOnly } from "react-device-detect";

class Messangers extends Component {
  getContacts = (mainDB) => {
    const contacts = mainDB.filter(function (item) {
      return item.category === "contacts";
    });
    return contacts;
  };

  handleOnClickViber = (contacts) => {
    isMobileOnly
      ? window.open("viber://chat/?number=380976971216", "_self")
      : window.open("viber://chat/?number=" + contacts[0].phone, "_self");
    /*     if (window.screen.width > 658) {
      window.open("viber://chat/?number=" + contacts[0].phone, "_self");
    } else {
      window.open("viber://chat/?number=380976971216", "_self");} */
    /* window.open("viber://add?number=" + contacts[0].phone, "_self") */
  };

  handleOnClickWhatsApp = (contacts) => {
    window.open("whatsapp://send?phone=" + contacts[0].phone, "_self");
  };

  handleOnClickTelegram = (contacts) => {
    window.open("tg://resolve?domain=" + contacts[0].telegram, "_self");
  };

  render() {
    const mainDB = this.props.mainDB;
    const contacts = mainDB.length > 0 && this.getContacts(mainDB);
    return (
      <Fragment>
        {contacts ? (
          <div
            className={styles["messangers-box"]}
            style={{ width: this.props.contactPage && "65%" }}>
            {!this.props.contactPage && (
              <div
                className={isMobileOnly ? styles.messangers_number_mob : styles.messangers_number}>
                <a
                  href={"tel:" + contacts[0].phone}
                  className={
                    isMobileOnly ? styles.messangers_number_link_mob : styles.messangers_number_link
                  }>
                  {contacts[0].phone_show}
                </a>
              </div>
            )}
            <div
              className={
                isMobileOnly ? styles["messangers-img-box_mob"] : styles["messangers-img-box"]
              }>
              <button
                type="button"
                className={isMobileOnly ? styles.messangers_btn_mob : styles.messangers_btn}
                title="Написать мне в Viber"
                onClick={() => this.handleOnClickViber(contacts)}
                target="_self"
                rel="noopenner noreferrer">
                <svg
                  className={
                    isMobileOnly ? styles.messangers_svg_box_mob : styles.messangers_svg_box
                  }>
                  <title>Написать мне в Viber</title>
                  <use xlinkHref={`${Icons}#viber`} className={styles.messangers_svg_viber} />
                </svg>
              </button>
              <button
                type="button"
                className={isMobileOnly ? styles.messangers_btn_mob : styles.messangers_btn}
                title="Написать мне в Whats App"
                onClick={() => this.handleOnClickWhatsApp(contacts)}
                target="_self"
                rel="noopenner noreferrer">
                <svg
                  className={
                    isMobileOnly ? styles.messangers_svg_box_mob : styles.messangers_svg_box
                  }>
                  <title>Написать мне в Whats App</title>
                  <use xlinkHref={`${Icons}#whatsapp`} className={styles.messangers_svg_whatsapp} />
                </svg>
              </button>
              <button
                type="button"
                className={isMobileOnly ? styles.messangers_btn_mob : styles.messangers_btn}
                title="Написать мне в Telegram"
                onClick={() => this.handleOnClickTelegram(contacts)}
                target="_self"
                rel="noopenner noreferrer">
                <svg
                  className={
                    isMobileOnly ? styles.messangers_svg_box_mob : styles.messangers_svg_box
                  }>
                  <title>Написать мне в Telegram</title>
                  <use xlinkHref={`${Icons}#telegram`} className={styles.messangers_svg_telegram} />
                </svg>
              </button>
            </div>
          </div>
        ) : (
          <div className={styles["messangers-box"]}>
            <Spinner params={50} />
          </div>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  mainDB: state.mainDB,
});

export default connect(mapStateToProps, null)(Messangers);
