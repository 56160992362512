import { createAction, createReducer } from '@reduxjs/toolkit';
const initialState = ({
    totalPrice: null
});

const countPrice = createAction('TOTAL_PRICE_FROM_BD_BUSCKET');

const countPriceSumReducer = createReducer(initialState, {

    [countPrice]: (state, action) => {
        let totalQuantityLocalStorage = 0
        let totalPriceLocalStorage = 0
        const currentWishes = JSON.parse(localStorage.getItem("bascketWishes"));
        if (currentWishes !== null) {
            for (let i = 0; i < currentWishes.length; i++) {
                let key = Object.keys(currentWishes[i])
                let value = Object.values(currentWishes[i])
                totalQuantityLocalStorage = totalQuantityLocalStorage + Number(value)

                const filterdItem = action.payload.filter(function (item) {
                    return item.id === key[0];
                })

                totalPriceLocalStorage = totalPriceLocalStorage + ((filterdItem[0].sale_price ? filterdItem[0].sale_price : filterdItem[0].price) * Number(value))
            }

        }

        return { totalPrice: totalPriceLocalStorage.toLocaleString('ru') }
    }
}
)

export default countPriceSumReducer;