import { configureStore } from '@reduxjs/toolkit';
import mainDbReducer from "./mainDB/mainDbReducer";
import buscetDbReducer from "./basketDB/basketDBReducer";
import countTotalQuantityReducer from "./basketDB/totalQuantityReducer.js";
import countTotalPriceReducer from "./basketDB/countTotalPriceReducer.js";
import currentGalleryPageReducer from "./gallery/currentGalleryPageReducer.js";
import reviewsDbReducer from "./reviewsDB/reviewsDbReducer"

const store = configureStore({
    reducer: {
        mainDB: mainDbReducer,
        basketDB: buscetDbReducer,
        totalQuantity: countTotalQuantityReducer,
        totalPrice: countTotalPriceReducer,
        reviewsDb: reviewsDbReducer,
        currentGalleryPage: currentGalleryPageReducer
    }
});

export default store;