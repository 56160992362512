import React, { Component, lazy, Suspense, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import styles from "./main.module.css";
import Spinner from "./loader/Loader";
import Header from "./header/header";
import Footer from "./footer/footer";
import Info from "./info/info";
import { connect } from "react-redux";
import * as mainDbActions from "../redux/mainDB/mainDbOperations";

import { isMobileOnly } from "react-device-detect";
import MobileHeader from "../mobile/components/header/header";
import MobileFooter from "./footer/socials";

const AsyncHomePage = lazy(() => import("../pages/home" /*webpackChunkName: "home-page" */));
const AsyncProductPage = lazy(() =>
  import("../pages/products" /*webpackChunkName: "Products-page" */),
);
const AsyncCardDetails = lazy(() =>
  import("../components/share/modals/modalCard" /*webpackChunkName: "modalCard-page" */),
);

const AsyncBasketPage = lazy(() =>
  import("../pages/basket.js" /*webpackChunkName: "Basket-page" */),
);

const AsyncInfoPage = lazy(() =>
  import("../pages/info_mobOnly.js" /*webpackChunkName: "Basket-page" */),
);

//Admin section
const AsyncAddIDPage = lazy(() =>
  import("../pages/Admin/addID" /*webpackChunkName: "Addid-page" */),
);

const AsyncNotFoundPage = lazy(() =>
  import("../pages/pageNotFound" /*webpackChunkName: "NotFound-page" */),
);

class App extends Component {
  state = {
    isActiveDesc: false,
  };

  UNSAFE_componentWillMount() {
    this.props.fetchMainDB();
  }

  componentDidUpdate() {
    this.scrollTo();
  }

  scrollTo() {
    window.scrollTo({
      top: (0, 0),
      behavior: "instant",
    });
  }

  closeModalItemCard = (e) => {
    this.setState({
      isActiveDesc: !this.state.isActiveDesc,
    });
  };

  render() {
    // Создание DB
    /* let req = new XMLHttpRequest();
 
        req.onreadystatechange = () => {
            if (req.readyState === XMLHttpRequest.DONE) {
                const mainBd = JSON.parse(req.responseText)
                console.log(mainBd);
            }
        };
 
        req.open("POST", "https://api.jsonbin.io/b", true);
        req.setRequestHeader("secret-key", "$2b$10$EmDaOTImproHwI8r4haMPOACBljRU8sDaL2viic.9uTHqD6pXLAgW");
        req.setRequestHeader("Content-Type", "application/json");
        req.setRequestHeader("name", "ChebretsMainDB");
        req.send(JSON.stringify(DB)); */

    // Обновление DB
    /*         let req = new XMLHttpRequest();
                req.onreadystatechange = () => {
                    if (req.readyState === XMLHttpRequest.DONE) {
                        const mainBd = JSON.parse(req.responseText)
                        console.log(mainBd);
                    }
                };
        
                req.open("PUT", "https://api.jsonbin.io/b/5f20404c91806166284aabf2", true);
                req.setRequestHeader("Content-Type", "application/json");
                req.setRequestHeader("secret-key", "$2b$10$EmDaOTImproHwI8r4haMPOACBljRU8sDaL2viic.9uTHqD6pXLAgW");
                req.setRequestHeader("versioning", false);
                req.send(JSON.stringify(DB)); */

    /*   let req = new XMLHttpRequest();
  
          req.onreadystatechange = () => {
              if (req.readyState === XMLHttpRequest.DONE) {
                  console.log(req.responseText);
                  const mainBd = JSON.parse(req.responseText)
                  console.log("И?")
              }
          };
          var params = {
              'apiKey': '33ce337e1373e00f3f14a1a07866a048',
              "modelName": "Address",
              "calledMethod": "getAreas",
              'methodProperties': {
                  'Language': 'UA'
              }
          };
          console.log(params)
          req.open("POST", `https://api.novaposhta.ua/v2.0/json/` + JSON.stringify(params));
          console.log(`https://api.novaposhta.ua/v2.0/json/` + JSON.stringify(params)) */

    /* var params = {
            'apiKey': 'ee3b080c5a011b47e46fc0357bc8a518',
            'modelName': 'TrackingDocument',
            'calledMethod': 'getStatusDocuments',
            'methodProperties': {
                'Documents': [{
                    'DocumentNumber': '20600000051731',
                    'Phone': '380ХХХХХХХХХ'
                }],
                'Language': 'UA'
            }
        }; */

    /*  req.open("POST", "https://api.jsonbin.io/b", true);
        {
    "modelName": "AddressGeneral",
    "calledMethod": "getWarehouses",
    "CityName":"Київ",
    "methodProperties": {
         "Language": "ru"
    },
    "apiKey": "33ce337e1373e00f3f14a1a07866a048"
}
 */

    return (
      <Fragment>
        {isMobileOnly ? (
          <div className={styles.mob_wrapper}>
            {<MobileHeader />}
            <div className={styles.mob_content_box}>
              <Suspense fallback={<Spinner />}>
                <Switch>
                  <Route path="/" exact component={AsyncHomePage} />
                  <Route path="/basket" component={AsyncBasketPage} />
                  <Route path="/:category/:paginationNumber/:cardId" component={AsyncCardDetails} />
                  <Route
                    path="/:category/:paginationNumber"
                    component={AsyncProductPage}
                    closeModalItemCard={this.closeModalItemCard}
                  />
                  <Route path="/:info" exact component={AsyncInfoPage} />
                  <Route path="/addid" component={AsyncAddIDPage} />
                  <Route component={AsyncNotFoundPage} />
                </Switch>
                <div className={styles.mob_footer_box}>
                  <MobileFooter />
                </div>
              </Suspense>
            </div>
          </div>
        ) : (
          <div className={styles.wrapper}>
            <Header />
            <Suspense fallback={<Spinner />}>
              <Switch>
                <Route path="/" exact component={AsyncHomePage} />
                <Route path="/basket" component={AsyncBasketPage} />
                <Route path="/:category/:paginationNumber/:cardId" component={AsyncCardDetails} />
                <Route
                  path="/:category/:paginationNumber"
                  component={AsyncProductPage}
                  closeModalItemCard={this.closeModalItemCard}
                />
                <Route path="/addid" component={AsyncAddIDPage} />
                <Route component={AsyncNotFoundPage} />
              </Switch>
            </Suspense>
            <Info />
            <Footer />
          </div>
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  fetchMainDB: mainDbActions.fetchMainDB,
};

export default connect(null, mapDispatchToProps)(App);
