import { createAction, createReducer } from '@reduxjs/toolkit';
const initialState = ({
    totalQuantity: null
});

const countTotalQuantity = createAction('TOTAL_QUANTITY_FROM_BD_BUSCKET');

const countTotalQuantityReducer = createReducer(initialState, {

    [countTotalQuantity]: (state, action) => {
        let newTotal = 0
        const currentWishes = JSON.parse(localStorage.getItem("bascketWishes"));
        if (currentWishes !== null) {
            for (let i = 0; i < currentWishes.length; i++) {
                let value = Object.values(currentWishes[i])
                newTotal = newTotal + Number(value)
            }
            return { totalQuantity: newTotal }
        }

    }


    /* Функция подсчета в массива значений ключа  
    //Action
    export const countTotalQuantity = createAction('TOTAL_QUANTITY_FROM_BD_BUSCKET', function prepare(bascketDB) {
    return {
        payload: bascketDB
    }
})      
    //Reducer
            let newTotal = 0;
            action.payload.map(item => newTotal += item.quantity)
            return {
                totalQuantity: newTotal
            } */
}




)


export default countTotalQuantityReducer;