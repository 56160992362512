import { createAction } from '@reduxjs/toolkit';

export const getReviewsDbSuccess = createAction('GET_REVIEWS_DB_SUCCESS', function prepare(items) {
    return {
        payload: items
    }

})

export const clearItemsFromReviewsDb = createAction('CLEAR_ITEMS_FROM_REVIEWS_DB', function prepare() {
    return {
        payload: []
    }
})
