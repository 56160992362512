import React, { Component, Fragment } from "react";
import styles from "./footer.module.css";
import * as notice from "../share/pnotify";
import Icons from "../../assets/img/sprite.svg";
import MaskedInput from "react-text-mask";
import Socials from "./socials";

export default class Footer extends Component {
  state = {
    phone: "",
    isNumberPhoneValid: true,
  };

  submitHandler = (e) => {
    e.preventDefault();
    window.Email.send({
      SecureToken: "02e97003-3ab4-449b-bd98-2058e3f03876",
      To: "mavistepreport@gmail.com",
      From: "mavistepreport@gmail.com",
      Subject: "Заявка на обратый звонок",
      Body: "Просят перезвонить по номеру:" + this.state.phone,
    }).then((message) => this.mailSendResult(message));
  };

  mailSendResult = (message) => {
    if (message === "OK") {
      notice.pnotifyOk("Ваше письмо успешно отправлено!");
      this.setState({
        phone: "",
        isNumberPhoneValid: true,
      });
    } else {
      notice.pnotifyError(
        "Ошибка сервера. Попробуйте позже, или свяжитесь со мной по телефону или мессенджеру",
      );
    }
  };

  changeHandler = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };
  //Reg exp for phone input number
  //pattern="[\+]\d{2}[\s]\d{3}[\s]\d{3}[\-]\d{2}[\-]\d{2}"
  render() {
    const { phone, isNumberPhoneValid } = this.state;

    return (
      <div
        className={
          this.props.contactPage
            ? styles.footer_socials_box
            : styles.footer_socials_box + " " + styles.footer_socials_box_margin
        }>
        <form className={styles.footer_form_callback} onSubmit={this.submitHandler}>
          <div className={styles.footer_form_input_box}>
            <MaskedInput
              mask={[
                "+",
                "3",
                "8",
                "(",
                /\d/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
              ]}
              className={
                !isNumberPhoneValid
                  ? styles["footer_form_input"] + " " + styles["footer_form_input_notValid"]
                  : styles["footer_form_input"]
              }
              placeholder="Куда мне перезвонить?"
              guide={false}
              id="my-input-id"
              onBlur={() => {}}
              name="phone"
              value={phone}
              autoComplete="off"
              onChange={this.changeHandler}
              required
            />

            <p className={styles.footer_input_subtitle}>обратный звонок</p>
          </div>
          <div className={styles.footer_socials_circle}>
            <button type="submit" className={styles.footer_socials_form_btn}>
              <svg className={styles.footer_socials_svg_box}>
                <title>Заказать обратный звонок</title>
                <use xlinkHref={`${Icons}#phone`} className={styles.footer_socials_svg} />
              </svg>
            </button>
          </div>
        </form>
        <Socials />
      </div>
    );
  }
}
